
import { defineComponent, ref, reactive } from "vue";
import {
  IonPage,
  IonContent,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { mapOutline } from "ionicons/icons";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useI18n } from "vue-i18n";
//import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";
import MainHeader from "../components/MainHeader.vue";
import Banner from "../components/Banner.vue";
import Pagination from "../components/Pagination.vue";
import MainFooter from "../components/MainFooter.vue";
export default defineComponent({
  name: "OurSellers",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    //const { getLocalStorageData } = useLocalStorage();
    const { createLoading } = useGlobalFunctions();
    const router = useRouter();
    const route = useRoute();
    const storeX = useStore();
    const icons = reactive({
      close,
      mapOutline,
    });
    const sellersState = ref([]);
    const selectedState = ref(null);
    const sellers = ref([]);

    const domainLangUrl = window.location.origin;
    const isAE = domainLangUrl.includes("ae.yallatager");
    const countryCode = isAE ? "AE" : "EG";

    const pages = ref(1);
    const currentPage = ref(0);
    const searchQuery = ref(null);

    const getsellersList = async (page = 1) => {
      currentPage.value = page;
      sellers.value = [];
      const loading = await createLoading(t("pleaseWait"));
      const params = {
        store_view: storeX.state.storeCode,
        vendor_name: searchQuery.value,
        filters: [
          {
            attribute_code: "state_id",
            filter: "eq",
            value: selectedState.value,
          },
        ],
        page: currentPage.value,
        limit: 20,
      };
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getSellersList"), params)
        .then(async (response: any) => {
          const resp = response;
          if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
            pages.value = Number(resp.pager.pages);
            resp.items.forEach((item: any) => {
              sellers.value.push(item);
            });
            loading.dismiss();
          } else {
            sellers.value = [];
            loading.dismiss();
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error);
        });
    };

    const getvendorStatesByCountry = () => {
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("getvendorStatesByCountry"),
          {
            store_view: storeX.state.storeCode,
            country_id: countryCode,
          }
        )
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            sellersState.value = resp.items;
          } else {
            sellersState.value = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const changeStateAction = async (target: any, event?: any) => {
      selectedState.value = target;
      getsellersList(event);
    };

    const goToVendorPage = (vendorCode) => {
      const url = "/" + storeX.state.storeCode + "/vendor/" + vendorCode;
      return url;
    };

    const getImageUrl = (seller) => {
      if (seller.logo_url) {
        return seller.logo_url;
      } else {
        return "assets/images/placeholder.png";
      }
    };

    const nav = (path, event) => {
      event.preventDefault();
      router.push(path);
    };

    const doFilter = () => {
      currentPage.value = 0;
      sellers.value = [];
      getsellersList();
    };

    const ionViewWillEnter = () => {
      getvendorStatesByCountry();
      getsellersList();
    };
    return {
      t,
      storeX,
      route,
      router,
      icons,
      sellersState,
      selectedState,
      changeStateAction,
      sellers,
      goToVendorPage,
      nav,
      ionViewWillEnter,
      searchQuery,
      getImageUrl,
      pages,
      currentPage,
      getsellersList,
      doFilter,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    MainHeader,
    Banner,
    Pagination,
    MainFooter,
  },
});
